import * as React from "react";
import {Link} from "gatsby";
import Layout from "../../components/layout/Layout";
import SplashBanner from "../../components/SplashBanner";
import SectionIntro from "../../components/SectionIntro";
import Testimonials from "../../components/testimonials/Testimonials";

const bannerImg = '../../banners/cv-writing_banner.jpg';

//RENDER
export default function CVWritingPage() {
    return (
        <Layout pageTitle={'CV & Resume Writing'}>
            <SplashBanner
                title={"CV & Resume Writing"}
                content={"A one of a kind service developed specifically for those wanting to maximise their career opportunities within the Energy Sector."}
                backgroundImg={bannerImg}
            />

            {/*An experienced service*/}
            <section id="equipmentBrokerageIntro">
                <div className="container">
                    <SectionIntro>
                        <h2>Professional Writing services</h2>
                        <p>CV, Resume, Cover Letter & LinkedIn.</p>
                    </SectionIntro>
                    <div className="split-content">
                        <div className="module text">
                            <h2>What to expect</h2>
                            <ul className="formatted-list">
                                <li>The knowledge of exactly what clients wish to see within an applicants profile</li>
                                <li>How to structure the document to engage each reader while remaining relevant to all
                                    suitable job applications
                                </li>
                                <li>How to accurately display competency and experience</li>
                                <li>How to make your profile rank higher in recruiter search results</li>
                            </ul>
                            <p>All with the purpose of creating a professional document to help increase the applicants
                                chances of employment within their desired sector.</p>
                            <div className="double-button">
                                <Link to={'/contact-us'} className="button primary arrow-light">
                                    <span>Request our support</span>
                                </Link>
                            </div>
                        </div>
                        <div className="module media">
                            <img
                                src={'../../article/cv-writing_article.jpg'}
                                alt="CV Writing Service"/>
                        </div>
                    </div>
                </div>
            </section>

            <Testimonials />

        </Layout>
    )
}
